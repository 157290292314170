<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant.sync="mini"
      :width="$vuetify.breakpoint.mdAndUp ? 340 : '100%'"
      app
      clipped
      class="fill-height pb-16 pb-md-0"
      style="max-height: calc(100vh - 64px) !important;"
    >
      <v-list-item class="py-2">
        <v-btn
          icon
          class="mr-3"
          @click.stop="$vuetify.breakpoint.mdAndUp ? mini = !mini : drawer = !drawer"
        >
          <v-icon>
            {{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
          </v-icon>
        </v-btn>

        <v-list-item-title class="text-h6">
          Filters
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list class="py-0" v-if="!mini">
        <status-filters :filters.sync="filters"  />
        <chain-filters :filters.sync="filters" />
        <currency-filters :filters.sync="filters" />
      </v-list>
    </v-navigation-drawer>

    <v-btn
      v-if="!drawer"
      class="filters-toggle py-5"
      color="primary"
      rounded
      elevation="5"
      @click="drawer = !drawer"
    >
      <v-icon left>
        mdi-list-status
      </v-icon>
      Filters
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'MarketplaceFiltersSidebar',
  components: {
    'chain-filters': () => import('@/features/marketplace-filters-sidebar/ChainFilters.vue'),
    'currency-filters': () => import('@/features/marketplace-filters-sidebar/CurrencyFilters.vue'),
    'status-filters': () => import('@/features/marketplace-filters-sidebar/StatusFilters.vue'),
  },
  data: () => ({
    drawer: false,
    snackbar: true,
    mini: false,
    filters: {
      onSale: false,
      notOnSale: false,
    },
  }),
  mounted() {
    console.log('filter mounted');
  },
};
</script>

<style lang="scss" scoped>
.filters-toggle {
  position: fixed;
  bottom: 35px;
  width: 80vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
</style>
